import React from 'react';

import { Spin } from 'antd';

export default function SpinFull(props) {
  return (
    <Spin>
      <div className="spin-full-spacer"></div>
    </Spin>
  );
};