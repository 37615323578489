import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';

import SuperTableSearch from './SuperTableSearch';
import SuperTableContent from './SuperTableContent';

import * as ToolDevice from '../tools/ToolDevice';

export default function SuperTable(props) {
  const [pagination, setPagination] = useState({
    pageSize: 10,
    showQuickJumper: true,
    showSizeChanger: true,
    showTotal: (total, range) => `${range[0]} - ${range[1]} (${I18n.t('tablePaginationTotal', {
      total,
    })})`,
    ...(props.pagination || {}),
  });
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const {
    id,
    tableKey,
    onTableKeyChange = () => {},
    selectable = false,
    expandable = {},
    columns = [],
    loadDataSource = () => {},
    onSelectedRowChange = () => {},
    showSearchInput = false,
    searchQueryParams = (values) => values,
    searchColumns = [],
  } = props;

  const defaultQueryParams = {};
  const locationQuery = ToolDevice.getQuery();
  searchColumns.forEach(column => {
    if (column.defaultValue !== undefined) {
      defaultQueryParams[column.key] = column.defaultValue;
    } else if (column.defaultChecked) {
      defaultQueryParams[column.key] = true;
    }
    if (locationQuery[column.key] !== undefined) {
      defaultQueryParams[column.key] = locationQuery[column.key];
    }
  });

  const [queryParams, setQueryParams] = useState(defaultQueryParams);

  // TODO bulk actions

  const onSearchChange = (values) => {
    setPagination({
      ...pagination,
      current: 1,
    });
    setQueryParams(searchQueryParams(values));
    onTableKeyChange(Math.random().toString());
  };

  return (
    <>
      {!!(showSearchInput || searchColumns.length) && (
        <SuperTableSearch
          showSearchInput={showSearchInput}
          onChange={onSearchChange}
          columns={searchColumns}
          defaultValues={defaultQueryParams}
        />
      )}
      <SuperTableContent
        id={id}
        key={tableKey}
        columns={columns}
        queryParams={queryParams}
        loadDataSource={loadDataSource}
        dataSource={dataSource}
        expandable={expandable}
        onChangeDataSource={setDataSource}
        selectable={selectable}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowChange={selectedRowKeys => {
          setSelectedRowKeys(selectedRowKeys);
          onSelectedRowChange(selectedRowKeys);
        }}
        onQueryParamsChange={queryParams => setQueryParams(queryParams)}
        onPaginationChange={pagination => {
          setPagination(pagination);
          setSelectedRowKeys([]);
        }}
        pagination={pagination}
      />
    </>
  );
};
