import React, { useState, useRef, useEffect } from 'react';
import { I18n } from 'react-redux-i18n';

import { Card, Input, Space } from 'antd';

import SuperTableSearchColumn from './SuperTableSearchColumn';

const { Search } = Input;

let searchInputTimeout = null;
let skippedFirstUpdate = false;

export default function SuperTableSearch(props) {
  const skippedFirstUpdate = useRef(false);

  const {
    showSearchInput = false,
    onChange = () => {},
    columns = [],
    defaultValues = {},
  } = props;

  const defaultForm = {};
  for (let column of columns) {
    defaultForm[column.key] = defaultValues[column.key];
  }

  const [form, setForm] = useState(defaultForm);

  useEffect(() => {
    if (!skippedFirstUpdate.current) {
      return;
    }

    if (searchInputTimeout) {
      clearTimeout(searchInputTimeout);
    }

    searchInputTimeout = setTimeout(() => {
      onChange(form);
    }, 300);
  }, [form]);

  useEffect (() => {
    skippedFirstUpdate.current = true;
  }, []);

  const renderSearchColumns = () => {
    return columns.map((column, index) => {
      return SuperTableSearchColumn({
        ...column,
        key: index,
        onChange: (value) => {
          setForm({
            ...form,
            [column.key]: value,
          });
        },
        value: form[column.key],
      });
    });
  };

  return (
    <Card className="search-form-container">
      {showSearchInput && (
        <div>
          <Search
            placeholder={I18n.t('tableSearchPlaceholder')}
            autoComplete="off"
            value={form.search}
            onChange={(e) => {
              setForm({
                ...form,
                search: e.target.value,
              });
            }}
          />
        </div>
      )}
      <div>
        <Space wrap>
          {renderSearchColumns()}
        </Space>
      </div>
    </Card>
  );
};
